.page-selector {
  height: 100vh;
  overflow-y: scroll;
  transition: width .5s;
}

.page-selector--opened {}

.pages {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.ps-page-thumbnail {
  height: 140px;
  width: 100px;
  margin-bottom: 1rem;
  border-radius: 8px !important;
  overflow: hidden;
  filter: grayscale(1);
  transition: transform .2s;
  transition: width .2s;
  opacity: .75;
  overflow: hidden;
  background: linear-gradient(rgba(255, 255, 255, 0), #8898aa);
  display: flex;
  flex-direction: row;
}

.page-selector--opened .ps-page-thumbnail {
  width: 320px;
}

.ps-page-thumbnail:hover {
  box-shadow: 0 0 2rem 0 #8898aa !important;
  filter: grayscale(0);
  transform: scale(1.015);
  cursor: pointer;
  opacity: 1;
}

.ps-page-thumbnail-is-active {
  filter: grayscale(0);
  opacity: 1;
}

.ps-page-thumbnail-image-wrapper {
  width: 100px;
  height: 100%;
}

.ps-page-thumbnail-image {
  width: 100px;
  height: 140px;
  object-fit: cover;
  object-position: top;
}

.ps-page-thumbnail-metadata-wrapper {
  padding: 12px;
  font-size: 12px;
  width: 100%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 2fr;
  scrollbar-width: none;
  row-gap: .5rem;
  align-items: start;
  justify-self: center;
}

.ps-page-thumbnail-metadata-wrapper::-webkit-scrollbar {
  display: none;
}

.ps-page-thumbnail-metadata-wrapper input {
  max-width: 120px;
}

.ps-page-thumbnail__metadata {
  padding: 1rem;
}

.ps-page-thumbnail__metadata * {
  display: block;
}

.top-buttons {
  background: linear-gradient(#8898aa, rgba(255, 255, 255, 0));
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  z-index: 100;
}

.top-buttons button {
  margin-bottom: 1rem;
  width: 100%;
}

.top-buttons button:hover {
  cursor: pointer;
}

.top-buttons button:disabled {
  pointer-events: none;
  opacity: .5;
}

.top-buttons button.info {
  background-color: transparent;
  border-radius: 0.5rem;
  background-image: linear-gradient(310deg, #627594, #a8b8d8);
  color: white;
  border: none;
  padding: 0.5rem 0;
}

.top-buttons button.success {
  color: white;
  background-image: linear-gradient(310deg, #2dce89, #2dcecc);
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0;
}

.bottom-buttons {
  background: linear-gradient(rgba(255, 255, 255, 0), #8898aa);
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;
  z-index: 100;
}


.page-number-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(255, 255, 255, 0), #8898aa);
  padding: 12px 0;
}

.page-number {
  font-size: 1.5rem;
  font-weight: 800;
}

/* toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.show-metadata-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mr-2 {
  margin-right: 1rem;
}